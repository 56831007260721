<template>
  <div>
    <div
      v-for="orglink in person.org_links"
      :org="orglink.organization"
      :key="`org-${orglink.pk}`"
    >
      <OrganizationIcon v-if="icons" :org="orglink.organization" class="pr-1" />
      <OrganizationLink :org="orglink.organization" />
      &ndash;
      <span class="font-weight-light">{{ orglink.relation }}</span>
    </div>
  </div>
</template>
<script>
import OrganizationIcon from "@/components/blocks/OrganizationIcon";
import OrganizationLink from "@/components/blocks/OrganizationLink";

export default {
  name: "PersonRolesList",

  components: { OrganizationLink, OrganizationIcon },

  props: {
    person: { required: true, type: Object },
    icons: { default: false, type: Boolean },
  },
};
</script>
