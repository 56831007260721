<template>
  <span>
    <a
      :href="contact.value"
      target="_blank"
      v-if="contact.category === 'link'"
      class="subdued"
      >{{ contact.value }}</a
    >
    <a
      :href="`mailto:${contact.value}`"
      v-else-if="contact.category === 'email'"
      class="subdued"
      >{{ contact.value }}</a
    >
    <a
      :href="`tel:${contact.value}`"
      v-else-if="contact.category === 'phone'"
      class="subdued"
      >{{ contact.value }}</a
    >
    <span v-else>
      {{ contact.value }}
    </span>
    <v-tooltip bottom v-if="contact.public_notes">
      <template #activator="{ on }">
        <v-icon small class="pl-1 pb-2" v-on="on"
          >mdi-information-outline</v-icon
        >
      </template>
      {{ contact.public_notes }}
    </v-tooltip>
  </span>
</template>

<script>
export default {
  name: "ContactText",

  props: {
    contact: { required: true, type: Object },
  },
};
</script>
