import { removeDiacritics } from "@/lib/diacritics";

function normalizeString(str) {
  return removeDiacritics(str.toLowerCase());
}

function extractSearchString(object, fields) {
  let parts = [];
  for (let field of fields) {
    parts.push(normalizeString(object[field]));
  }
  return parts.join(" ");
}

function matches(search, text) {
  for (let part of search) {
    if (!text.includes(part)) return false;
  }
  return true;
}

function search(text, objects, field) {
  // returns objects for which the `field` matches the search text `text`
  const parts = normalizeString(text).split(" ");
  return objects.filter((obj) => matches(parts, obj[field]));
}

export { extractSearchString, search };
