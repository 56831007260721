<template>
  <v-autocomplete
    v-model="selection"
    :items="allTags"
    :loading="loading"
    item-text="name"
    item-value="pk"
    return-object
    clearable
    :label="label"
  >
    <template #item="{ item }">
      <span>
        <v-icon
          :color="item.category.color ? item.category.color : 'black'"
          class="mr-2"
        >
          mdi-tag
        </v-icon>
        {{ item.name }}
      </span>
      <v-spacer />
      <span class="subdued">{{ item.category.name }}</span>
    </template>

    <template #selection="{ item }">
      <v-icon
        :color="item.category.color ? item.category.color : 'black'"
        class="mr-2"
      >
        mdi-tag
      </v-icon>

      <span class="subdued mr-1">{{ item.category.name }} /</span>
      <span>{{ item.name }}</span>
    </template>
  </v-autocomplete>
</template>
organization/78

<script>
import axios from "axios";

export default {
  name: "TagSelector",

  props: {
    value: { required: false, type: Object },
    label: { required: false, type: String },
  },

  data() {
    return {
      selection: null,
      allTags: [],
      loading: false,
    };
  },

  methods: {
    async fetchTags() {
      this.loading = true;
      try {
        let resp = await axios.get("/api/tag/");
        this.allTags = resp.data;
        this.allTags.sort((a, b) => a.name.localeCompare(b.name));
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.fetchTags();
  },

  watch: {
    selection() {
      this.$emit("input", this.selection);
    },
    value() {
      this.selection = this.value;
    },
  },
};
</script>

<style scoped></style>
