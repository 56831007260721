<template>
  <v-data-table
    :items="people"
    :headers="headers"
    sort-by="last_name"
    :search="filter"
    :loading="loading"
    locale="cs"
  >
    <template #item.title_before="{ item }">
      {{ item.title_before
      }}<span v-if="item.title_before && item.title_after">, </span
      >{{ item.title_after }}
    </template>

    <template #item.last_name="{ item }">
      <router-link
        class="subdued"
        :to="{ name: 'personDetail', params: { personId: item.pk } }"
        >{{ item.last_name }}</router-link
      >
    </template>

    <template #item.emails="{ item }">
      <div v-for="(contact, index) in item.emails_" :key="index">
        <PersonOrOrgContactIcon :match="item.match" v-if="showMatch" />
        <ContactText :contact="contact" />
      </div>
    </template>

    <template #item.phones="{ item }">
      <div v-for="(contact, index) in item.phones_" :key="index">
        <PersonOrOrgContactIcon :match="item.match" v-if="showMatch" />
        <ContactText :contact="contact" />
      </div>
    </template>

    <template #item.roles="{ item }">
      <PersonRolesList :person="item" />
    </template>

    <template #item.match="{ item }">
      <v-icon color="#999999">{{
        item.match === "perorg" ? "mdi-domain" : "mdi-account"
      }}</v-icon>
    </template>
  </v-data-table>
</template>

<script>
import ContactText from "@/components/blocks/ContactText";
import PersonRolesList from "@/components/PersonRolesList";
import PersonOrOrgContactIcon from "@/components/icons/PersonOrOrgContactIcon";

export default {
  name: "PersonTable",
  components: { PersonOrOrgContactIcon, PersonRolesList, ContactText },
  props: {
    people: { required: true, type: Array },
    filter: { type: String, default: "" },
    loading: { default: false, type: Boolean },
    showMatch: { default: false, type: Boolean }, // use icons to mark contacts using person.match
    debugMatch: { default: false, type: Boolean },
  },

  data() {
    return {};
  },

  computed: {
    headers() {
      let base = this.debugMatch ? [{ text: "match", value: "match" }] : [];
      return [
        ...base,
        { text: this.$t("label.lastName"), value: "last_name" },
        { text: this.$t("label.firstName"), value: "first_name" },
        {
          text: this.$t("label.titles"),
          value: "title_before",
          sortable: false,
        },
        { text: this.$t("label.emails"), value: "emails", sortable: false },
        { text: this.$t("label.phones"), value: "phones", sortable: false },
        { text: this.$t("label.roles"), value: "roles", sortable: false },
      ];
    },
  },
};
</script>

<style scoped></style>
