<template>
  <v-container fluid style="max-width: 1280px" class="px-md-6">
    <v-row v-if="showDialog">
      <v-col>
        <v-card>
          <v-card-text>
            <EditPersonWidget />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4 pt-8">
      <v-col cols="12" md="6">
        <v-combobox
          v-model="selectedObject"
          :label="$t('label.search')"
          prepend-inner-icon="mdi-magnify"
          :loading="loading"
          :items="matchingRecords"
          item-value="pk"
          :search-input.sync="search"
          hide-no-data
          @keydown.enter="updateData"
          return-object
          clearable
          no-filter
        >
          <template #item="{ item }">
            <PersonIcon :person="item" v-if="item.type === 'person'" />
            <OrganizationIcon :org="item" v-else />
            <span class="pl-2">{{ item.text }}</span>
          </template>
        </v-combobox>
      </v-col>
      <v-col cols="12" md="5">
        <TagSelector v-model="selectedTag" :label="$t('label.tagFilter')" />
      </v-col>
      <v-col cols="auto" class="align-self-center" v-if="canAdd">
        <IconMenu :items="addMenuItems" icon="mdi-plus" />
      </v-col>
    </v-row>
    <v-row v-if="toShow">
      <v-col>
        <v-card>
          <div class="ghost pt-2">
            <v-tabs v-model="tab" background-color="ghost">
              <v-tab href="#person">
                <div class="mx-md-4">
                  <v-icon class="pr-1">mdi-account</v-icon>
                  <v-badge :content="'' + matchingPeople.length">{{
                    $t("label.people")
                  }}</v-badge>
                </div>
              </v-tab>
              <v-tab href="#organization">
                <div class="mx-4">
                  <v-icon class="pr-1">mdi-domain</v-icon>
                  <v-badge :content="'' + matchingOrganizations.length"
                    >{{ $t("label.organizations") }}
                  </v-badge>
                </div>
              </v-tab>
              <v-spacer></v-spacer>
              <div class="pr-4">
                <v-btn :href="exportLink" color="secondary">
                  <v-icon class="mr-1">mdi-download</v-icon>
                  {{ $t("button.export") }}
                </v-btn>
              </div>
            </v-tabs>
          </div>
          <v-tabs-items v-model="tab" class="pt-6 mx-4">
            <v-tab-item value="person">
              <PersonTable
                :people="matchingPeople"
                :loading="loadingPeople"
                :show-match="selectedTag !== null"
                :debug-match="debugMatch"
              />
              <div class="pb-4" v-if="can('add', 'person')">
                <v-btn color="primary" @click="showAddPersonDialog = true">
                  <v-icon small>mdi-plus</v-icon>
                  {{ $t("label.addPerson") }}
                </v-btn>
              </div>
            </v-tab-item>
            <v-tab-item value="organization">
              <OrganizationTable
                :organizations="matchingOrganizations"
                :loading="loadingOrgs"
              />
              <div class="pb-4" v-if="can('add', 'organization')">
                <v-btn color="primary" @click="showAddOrgDialog = true">
                  <v-icon small>mdi-plus</v-icon>
                  {{ $t("label.addOrganization") }}
                </v-btn>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>
    <EditPersonDialog
      v-if="showAddPersonDialog"
      v-model="showAddPersonDialog"
      @save="goToNewPerson"
    />
    <EditOrganizationDialog
      v-if="showAddOrgDialog"
      v-model="showAddOrgDialog"
      @save="goToNewOrg"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import debounce from "lodash/debounce";
import PersonTable from "@/components/PersonTable";
import EditPersonWidget from "@/components/forms/EditPersonForm";
import { mapGetters } from "vuex";
import IconMenu from "@/views/IconMenu";
import { preprocessObjectWithContacts } from "@/lib/contacts";
import OrganizationTable from "@/components/OrganizationTable";
import TagSelector from "@/components/blocks/TagSelector";
import EditPersonDialog from "@/components/dialogs/EditPersonDialog";
import EditOrganizationDialog from "@/components/dialogs/EditOrganizationDialog";
import PersonIcon from "@/components/blocks/PersonIcon";
import OrganizationIcon from "@/components/blocks/OrganizationIcon";
import { extractSearchString, search } from "@/lib/search";

export default {
  name: "Home",
  components: {
    OrganizationIcon,
    PersonIcon,
    EditOrganizationDialog,
    EditPersonDialog,
    TagSelector,
    OrganizationTable,
    IconMenu,
    EditPersonWidget,
    PersonTable,
  },
  data() {
    return {
      q: "",
      toShow: [],
      loadingPeople: false,
      loadingOrgs: false,
      allPeople: [],
      search: null,
      selectedObject: null,
      allOrganizations: [],
      showDialog: false,
      matchingPeople: [],
      matchingOrganizations: [],
      menu: null,
      selectedTag: null,
      tagPerOrgs: [], // per-orgs matching the current `selectedTag`
      debugMatch: false, // for debugging purposes only
      showAddPersonDialog: false,
      showAddOrgDialog: false,
    };
  },

  computed: {
    ...mapGetters(["can"]),
    tab: {
      set(tab) {
        this.$router.replace({ query: { ...this.$route.query, tab } });
      },
      get() {
        return this.$route.query.tab ?? "person";
      },
    },
    matchingRecords() {
      if (!this.search) {
        return [];
      }
      let out = [];
      if (this.search.length >= 3) {
        search(this.search, this.allPeople, "searchText_").forEach((item) =>
          out.push(item)
        );
        search(
          this.search,
          this.allOrganizations,
          "searchText_"
        ).forEach((item) => out.push(item));
      }
      return out;
    },
    qDebounced: {
      get() {
        return this.q;
      },
      set: debounce(function (value) {
        this.q = value;
      }, 500),
    },
    canAdd() {
      return this.can("add", "person") || this.can("add", "organization");
    },
    addMenuItems() {
      let ret = [];
      if (this.can("add", "person")) {
        ret.push({
          objType: "person",
          title: this.$t("label.person"),
          icon: "mdi-account",
          action: this.addObject,
        });
      }
      if (this.can("add", "organization")) {
        ret.push({
          objType: "organization",
          title: this.$t("label.organization"),
          icon: "mdi-domain",
          action: this.addObject,
        });
      }
      return ret;
    },
    loading() {
      return this.loadingOrgs || this.loadingPeople;
    },
    searchQueryParams() {
      let params = {};
      if (this.search) {
        params["q"] = this.search;
      }
      if (this.selectedTag) {
        params["tag"] = this.selectedTag.pk;
      }
      return params;
    },
    exportLink() {
      if (this.tab === "organization") {
        const params = {
          ...this.searchQueryParams,
          name: this.$t("label.organizationName"),
          emails: this.$t("label.emails"),
          phones: this.$t("label.phones"),
          address: this.$t("label.address"),
        };
        const q = new URLSearchParams(Object.entries(params)).toString();
        return `/api/organization-rich/export/?${q}`;
      } else if (this.tab === "person") {
        const params = {
          ...this.searchQueryParams,
          first_name: this.$t("label.firstName"),
          last_name: this.$t("label.lastName"),
          titles: this.$t("label.titles"),
          emails: this.$t("label.emails"),
          phones: this.$t("label.phones"),
          roles: this.$t("label.roles"),
        };
        const q = new URLSearchParams(Object.entries(params)).toString();
        return `/api/person-rich/export/?${q}`;
      }
      return "";
    },
  },

  methods: {
    async fetchPeople() {
      this.allPeople = [];
      this.loadingPeople = true;
      try {
        let resp = await axios.get("/api/person/");
        this.allPeople = resp.data;
        this.allPeople.forEach((item) => {
          item.text = `${item.first_name} ${item.last_name}`;
          item.searchText_ = extractSearchString(item, [
            "first_name",
            "last_name",
            "middle_name",
            "born_last_name",
            "title_before",
            "title_after",
          ]);
          item.type = "person";
        });
      } catch (error) {
        console.error("Error fetching people: ", error);
      } finally {
        this.loadingPeople = false;
      }
    },
    async fetchOrganizations() {
      this.allOrganizations = [];
      this.loadingOrgs = true;
      try {
        let resp = await axios.get("/api/organization/");
        this.allOrganizations = resp.data;
        this.allOrganizations.forEach((item) => {
          item.text = item.name;
          item.searchText_ = extractSearchString(item, ["name"]);
          item.type = "org";
        });
      } catch (error) {
        console.error("Error fetching organizations: ", error);
      } finally {
        this.loadingOrgs = false;
      }
    },
    async fetchMatchingPersons() {
      this.loadingPeople = true;
      // if we are on the other tab, we only load simple results for counting
      let mode = this.tab === "person" ? "-rich" : "";
      try {
        let perorgPromise = this.fetchMatchingTaggedPerOrgs();
        // people
        let resp = await axios.get(`/api/person${mode}/`, {
          params: this.searchQueryParams,
        });
        this.matchingPeople = resp.data;
        if (this.tab === "person") {
          this.matchingPeople.forEach((item) =>
            preprocessObjectWithContacts(item)
          );
        }
        await perorgPromise;
        if (this.tagPerOrgs) {
          // we need to mix and match the matching people with the perOrgs
          // prepare the mapping for more efficient search
          let perIdToPerOrgId = new Map();
          this.tagPerOrgs.forEach((item) => {
            if (!perIdToPerOrgId.has(item.person)) {
              perIdToPerOrgId.set(item.person, new Set());
            }
            perIdToPerOrgId.get(item.person).add(item.pk);
          });
          this.matchingPeople.forEach((person) => {
            if (perIdToPerOrgId.has(person.pk)) {
              // some per-orgs for this person match the selected tag
              let perOrgIds = perIdToPerOrgId.get(person.pk);
              person.org_links = person.org_links.filter((link) =>
                perOrgIds.has(link.pk)
              );
              person.match = "perorg";
              // replace phone and email with the ones from org_links
              let newPhones = [];
              let newEmails = [];
              person.org_links.forEach((link) => {
                link.contacts.forEach((contact) => {
                  contact.category = contact.contact_type.category.alias;
                  if (contact.contact_type.category.alias === "email")
                    newEmails.push(contact);
                  if (contact.contact_type.category.alias === "phone")
                    newPhones.push(contact);
                });
              });
              person.phones_ = newPhones;
              person.emails_ = newEmails;
            } else {
              // no per-orgs match the selected tag - the person itself must match
              person.match = "direct";
            }
          });
        }
      } finally {
        this.loadingPeople = false;
      }
    },
    async fetchMatchingOrganizations() {
      this.loadingOrgs = true;
      let mode = this.tab === "organization" ? "-rich" : "";
      try {
        let resp = await axios.get(`/api/organization${mode}/`, {
          params: this.searchQueryParams,
        });
        this.matchingOrganizations = resp.data;
        if (this.tab === "organization") {
          this.matchingOrganizations.forEach((item) =>
            preprocessObjectWithContacts(item)
          );
        }
      } finally {
        this.loadingOrgs = false;
      }
    },
    async fetchMatchingTaggedPerOrgs() {
      if (this.selectedTag) {
        let resp = await axios.get(
          `/api/tag/${this.selectedTag.pk}/perorglink-simple/`
        );
        this.tagPerOrgs = resp.data;
      } else {
        this.tagPerOrgs = [];
      }
    },
    async updateData() {
      await Promise.all([
        this.fetchMatchingPersons(),
        this.fetchMatchingOrganizations(),
      ]);
    },
    addObject(obj) {
      if (obj.objType === "person") {
        this.showAddPersonDialog = true;
      } else if (obj.objType === "organization") {
        this.showAddOrgDialog = true;
      }
      console.debug("adding object", obj);
    },
    goToNewPerson(person) {
      this.$router.push({
        name: "personDetail",
        params: { personId: person.pk },
      });
    },
    goToNewOrg(org) {
      this.$router.push({
        name: "organizationDetail",
        params: { organizationId: org.pk },
      });
    },
  },

  watch: {
    q() {
      this.updateData();
    },
    selectedObject() {
      if (this.selectedObject) {
        if (this.selectedObject.type === "person") {
          this.$router.push({
            name: "personDetail",
            params: { personId: this.selectedObject.pk },
          });
        } else if (this.selectedObject.type === "org") {
          this.$router.push({
            name: "organizationDetail",
            params: { organizationId: this.selectedObject.pk },
          });
        }
      }
    },
    tab() {
      if (this.tab === "person") {
        this.fetchMatchingPersons();
      } else {
        this.fetchMatchingOrganizations();
      }
    },
    selectedTag() {
      this.matchingPeople = [];
      this.updateData();
    },
  },

  async mounted() {
    await this.fetchPeople();
    await this.fetchOrganizations();
    await this.updateData();
  },
};
</script>
