<template>
  <v-tooltip bottom>
    <template #activator="{ on }">
      <v-icon color="#dadada" class="mr-1" v-on="on"
        >{{ match === "perorg" ? "mdi-domain" : "mdi-account" }}
      </v-icon>
    </template>
    {{
      match === "perorg"
        ? $t("label.organizationContact")
        : $t("label.personalContact")
    }}
  </v-tooltip>
</template>
<script>
export default {
  name: "PersonOrOrgContactIcon",
  props: {
    match: { required: true, type: String },
  },
};
</script>
