<template>
  <v-data-table
    :items="organizations"
    :headers="headers"
    sort-by="name"
    :search="filter"
    :loading="loading"
    locale="cs"
  >
    <template #item.name="{ item }">
      <OrganizationLink :org="item" />
    </template>

    <template #item.emails="{ item }">
      <div v-for="(contact, index) in item.emails_" :key="index">
        <ContactText :contact="contact" />
      </div>
    </template>

    <template #item.phones="{ item }">
      <div v-for="(contact, index) in item.phones_" :key="index">
        <ContactText :contact="contact" />
      </div>
    </template>

    <template #item.address="{ item }">
      <AddressWidget :address="item.address" />
    </template>
  </v-data-table>
</template>

<script>
import ContactText from "@/components/blocks/ContactText";
import OrganizationLink from "@/components/blocks/OrganizationLink";
import AddressWidget from "@/components/blocks/AddressWidget";

export default {
  name: "OrganizationTable",
  components: { AddressWidget, OrganizationLink, ContactText },
  props: {
    organizations: { required: true, type: Array },
    filter: { type: String, default: "" },
    loading: { default: false, type: Boolean },
  },

  data() {
    return {};
  },

  computed: {
    headers() {
      return [
        { text: this.$t("label.organizationName"), value: "name" },
        { text: this.$t("label.emails"), value: "emails", sortable: false },
        { text: this.$t("label.phones"), value: "phones", sortable: false },
        { text: this.$t("label.address"), value: "address", sortable: false },
      ];
    },
  },
};
</script>

<style scoped></style>
